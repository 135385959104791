@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,900|Open+Sans:500,700,800|Amatic+SC:400,700')
$OpenSans: 'Open Sans', sans-serif
$Montserrat: 'Montserrat', sans-serif
$Amatic: 'Amatic SC', cursive

//font-family: 'Montserrat', sans-serif
//font-family: 'Open Sans', sans-serif

.blue
  background-color: #00aee9 !important



nav ul a, nav .brand-logo
  color: #000

nav
  .container
    width: 88%
  .social-icon
    width: 4em
    text-align: center
p
  line-height: 1.4rem


.slides
  li
    .caption
      h5, h3
        span
          font-family: $Montserrat
          font-weight: 300
          font-size: 1.2em
          background-color: rgba(0, 0, 0, .4)
          padding: 0 20px
          border-radius: 3px
          line-height: 1.21em

.sidenav-trigger
  color: #00aee9

.parallax-container
  min-height: 380px
  line-height: 0
  height: auto
  color: rgba(255, 255, 255, .9)

  .parallax-container .section
    width: 100%

@media only screen and (max-width: 992px)
  .parallax-container .section
    position: absolute
    top: 40%

  #index-banner .section
    top: 10%

@media only screen and (max-width: 600px)
  #index-banner .section
    top: 0

.icon-block
  padding: 0 15px

.icon-block .material-icons
  font-size: inherit

#logo-container
  .logo-img
    min-width: 140px

.titulos
  padding-top: 2em
  padding-bottom: 3em
  max-width: 90%
  margin: 0 auto
  > *
    font-size: 3em
  .fondo
    font-family: 'Montserrat', sans-serif
    font-size: 6em
    height: 0
    text-align: right
    color: #00aee9
    text-transform: uppercase
    font-weight: 900
  .quote
    padding-top: 2em
    line-height: 1em
    font-family: 'Montserrat', sans-serif

    span
      font-weight: 500
      font-family: 'Montserrat', sans-serif
      border-left: 3px solid black
      padding-left: 1em
    .bold
      font-weight: 900

.tarjetas
  font-family: $Amatic
  margin-bottom: 0
  p
    font-size: 1.8em
    line-height: 1.2em
  .col
    background: #00aee9
    height: 50vh
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover
  .localidad
    color: #f1f1f1
    //font-size: 19em !important
    h3
      font-size: 6em
  .tarjeta-1
    background: url(../img/pangui_ph/IMG_2911editwin.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover
  .tarjeta-2
    background: url(../img/plaza.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover

  .tarjeta-3
    background: url(../img/iglesia.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover

  .chauquen-img1
    background: url(../img/chauqenps/IMG_3304.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover

  .chauquen-img2
    background: url(../img/chauqenps/IMG_3344.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover


  .huilo-img1
    background: url(../img/huilo_huilo_ph/14553906411206535482.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover


  .huilo-img2
    background: url(../img/huilo_huilo_ph/saltos_del_huilo_huilo.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover


  .cochuenco1
    background: url(../img/choshuenco_ph/IMG_3227.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover


  .cochuenco2
    background: url(../img/choshuenco_ph/IMG_3111.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover


  .fuy1
    background: url(../img/pto_fuy_ph/IMG_3177.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover


  .fuy2
    background: url(../img/pto_fuy_ph/IMG_3174.jpg) center no-repeat
    -webkit-background-size: cover
    -moz-background-size: cover
    -o-background-size: cover
    background-size: cover





.resenna
  height: 100%
  .col
    height: 50vh

.masonry-elements:after
  content: ''
  display: block
  clear: both



.slider .indicators .indicator-item
  margin: 0 3px
  bottom: 60px
  z-index: 20

.slider .indicators .indicator-item.active
  background-color: #00aee9

.page-footer
  background: #1a1a1a
  padding: 50px 0 20px 0
  line-height: 2em
  font-family: 'Montserrat', sans-serif !important
  font-weight: 200 !important
  h5
    margin-bottom: 20px
    font-weight: 200 !important
  .social-link
    font-size: 2em
    margin-bottom: 1em
  .col
    padding: 0
  .container
    width: 90%



.experiencias
  background-color: #00aee9 !important
  color: #f1f1f1
  font-family: $Amatic
  font-weight: 900
  margin: 0
  text-align: center
  padding: 10px 0
  h3
    width: 100%
    padding-top: 3px
    text-align: center !important

.info-experiencias
  background-color: #f0f0f0
  font-family: $Amatic
  p
    font-size: 1.3em
.turismo-las-palmeras
  margin-bottom: 0




.grid
  height: 30px

/* clear fix */
.grid:after
  content: ''
  display: block
  clear: both

/* ---- .grid-item ---- */

.grid-sizer, .grid-item
  padding: 2px
  width: 16.666%

.size-2
  width: 33.333%
  height: 250px

.size-3
  width: 83.333%
  height: 500px
.size-3
  width: 16.666%
  height: 500px
.grid-item
  float: left
.grid-item img
  display: block
  width: 100%




@media only screen and (max-width: 1278px)
  .titulos
    .fondo
      font-size: 5em

@media only screen and (max-width: 1000px)
  .titulos
    .fondo
      font-size: 4em

@media only screen and (max-width: 800px)
  .titulos
    .fondo
      font-size: 3em
    .quote
      font-size: 1.5em
  .tarjetas
    .localidad
      font-size: .6em
  .galeria
    height: 400px


@media only screen and (max-width: 600px)
  .titulos
    padding: 0
    padding-bottom: 12px
    .fondo
      font-size: 2em
    .quote
      font-size: 1em

  .grid, .grid-item, .galeria
    visibility: hidden
    height: 0

@media only screen and (max-width: 600px)
  .titulos
    .fondo
      font-size: 1.5em
    .quote
      font-size: 1em

.collage-row
  padding-top: 3em
  .col
    padding: 0